import {Header, Segment} from "semantic-ui-react";
import {textResources} from "../language/TextResources";
import {DeleteAccount} from "../users/DeleteAccount";
import {useContext} from "react";
import {OrganizationContext} from "../contexts/OrganizationContext";
import {IndividuallyEditableText} from "./IndividuallyEditableText";
import {ManagementConsoleAPIContext} from "@ametektci/ametek.stcappscommon";
import {EmailChangeModal} from "./EmailChangeModal";

export function ProfileSection() {
    const mgmtApi = useContext(ManagementConsoleAPIContext)
    const orgContext = useContext(OrganizationContext)
    const users = orgContext.getOrganizationUsers()
    const otherAdmins = users.filter(u => u.isAdmin && u.username != orgContext.user!.username)

    return (
        <Segment>
            <Header content={textResources.Strings.profile} size='large'/>

            <IndividuallyEditableText
                propertyLabel={textResources.Strings.name}
                onSubmit={mgmtApi.updateUserName}
                value={orgContext.user?.name ?? ""}
            />
            <EmailChangeModal
                currentEmail={mgmtApi.user?.email ?? ""}
                propertyLabel={textResources.Strings.emailAddress}
            />
            <Header dividing content={textResources.Strings.deleteAccount} color={"red"} size={"small"}/>
            { /* Certain functionality requires an admin be in the organization. */}
            {users.length > 1 && otherAdmins.length == 0 ? textResources.Strings.assignAnotherAdminFirst :
                <DeleteAccount/>}

        </Segment>
    )
}