import {User} from "@ametektci/ametek.stcappscommon";
import {Header, List, Segment} from "semantic-ui-react";
import {textResources} from "../language/TextResources";
import RemoveUser from "./RemoveUser";
import SetUserAdmin from "./SetUserAdmin";
import {ReSendInvite} from "./ReSendInvite";
import {useContext} from "react";
import {OrganizationContext} from "../contexts/OrganizationContext";

export function UserListing({user}: { user: User }) {
    const orgContext = useContext(OrganizationContext)
    const currentUser = orgContext.user!
    return (
        <List.Item key={user.email}>
            <Segment clearing>
                <div style={{display: "flex", flexDirection: "row"}}>
                    <div style={{flexGrow: 1}}>
                        <Header content={user.name} subheader={user.email} />
                    </div>
                    <div style={{textAlign: "right"}}>
                        <Header.Subheader content={user.isAdmin ? textResources.Strings.admin : textResources.Strings.standardUser} />
                        <br />
                        {currentUser.userId === user.userId ? null :
                            <span>
                                <ReSendInvite user={user}/>
                                <SetUserAdmin user={user}/>
                                <RemoveUser user={user}/>
                            </span>
                        }
                    </div>
                </div>
            </Segment>
        </List.Item>
    )
}