import {createContext, PropsWithChildren, useContext, useEffect, useState} from "react";
import {Gauge} from "@ametektci/ametek.stcappscommon";
import axios, {AxiosResponse} from "axios";
import {GaugesRequest, GaugesResponse} from "../Models/API/CrystalControlWeb/GaugesRequest";
import {GetEnvironmentSetting} from "../utils/EnvironmentSettings";
import {AuthContext} from "@ametektci/ametek.stcappscommon";

export interface GaugesContextInterface {
    gauges: Gauge[]
    refresh: () => void
}

export const GaugesContext = createContext<GaugesContextInterface>({
    gauges: [],
    refresh: () => {}
})

export function GaugesContextWrapper(props: PropsWithChildren) {
    const auth = useContext(AuthContext)
    const [gauges, setGauges] = useState<Array<Gauge>>([])
    useEffect(() => {
        refresh()
    }, [])
    const refresh = () => {
        auth.getAccessToken().then(token =>
            axios.post<GaugesResponse, AxiosResponse<GaugesResponse>, GaugesRequest>(GetEnvironmentSetting('CcwApiUrl') + "/Gauges", {
            }, {headers: {"Authorization":"bearer " + token}
            }).then((response) => {
                    setGauges(response.data.gauges ?? [])
                }
            )
        )
    }
    return (
        <GaugesContext.Provider value={{
            gauges,
            refresh
        }}>
            {props.children}
        </GaugesContext.Provider>
    )
}