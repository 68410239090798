import {Button, Grid, Header, Input, Label, Message, Modal, Segment} from 'semantic-ui-react';
import {textResources} from "../language/TextResources";
import {SyntheticEvent, useContext, useState} from "react";
import {OrganizationContext} from "../contexts/OrganizationContext";
import {SubscriptionsContext} from "../contexts/SubscriptionsContext";

type cardFields = "ccn"|"cvv"|"exp"
export default function AddNewCardModal() {
    const subsContext = useContext(SubscriptionsContext)
    const orgContext = useContext(OrganizationContext)
    const [firstNameOnCard, setFirstNameOnCard] = useState('')
    const [lastNameOnCard, setLastNameOnCard] = useState('')
    const [ccnValid, setCcnValid] = useState<boolean>(true)
    const [expValid, setExpValid] = useState<boolean>(true)
    const [ccvValid, setCcvValid] = useState<boolean>(true)
    const [tokenLoaded, setTokenLoaded] = useState(false)
    const [loading, setLoading] = useState(false)
    const [visible, setVisible] = useState(false)
    const [pfToken, setPfToken] = useState<string>()
    const onCardAdded = () => {
        setVisible(false)
        setLoading(false)
    }

    const open = () => {
        setVisible(true)
        orgContext.getBlueSnapToken().then(token => {
            setPfToken(token)
            setTokenLoaded(true)
            loadBlueSnap(token)
        })
    }
    const close = () => {
        if ( ! loading)
        {
            setVisible(false)
            setTokenLoaded(false)
        }
    };

    const updateCodeValids = (tag: cardFields, valid: boolean) =>
    {
        switch (tag)
        {
            case "ccn":
                setCcnValid(valid)
                break
            case "exp":
                setExpValid(valid)
                break
            case "cvv":
                setCcvValid(valid)
                break
        }
    }
    const loadBlueSnap = (paymentFieldToken : string) => {
        let bsObj = {
            token: paymentFieldToken,
            onFieldEventHandler: {
                // /*OPTIONAL*/ setupComplete: function () {
                //     console.warn("setupComplete");
                // },
                // /*OPTIONAL*/ threeDsChallengeExecuted: function () {
                //     console.warn("threeDsChallengeExecuted");
                // },
                // tagId returns: "ccn", "cvv", "exp"
                onFocus: function (tagId : cardFields) {}, // Handle focus
                onBlur: function (tagId: cardFields) {}, // Handle blur
                onError : (tagId : cardFields, errorCode: string) => {
                    console.log('on error', tagId, errorCode)
                    updateCodeValids(tagId, false)
                },
                // Handle a change in validation
                /*errorCode returns:
                        "10" --> invalidCcNumber, invalidExpDate, invalidCvv Dependent on the tagId;
                        "22013" --> "CC type is not supported by the merchant";
                        "14040" --> " Token is expired";
                        "14041" --> " Could not find token";
                        "14042" --> " Token is not associated with a payment method, please verify your client integration or contact BlueSnap support";
                        "400" --> "Session expired please refresh page to continue";
                        "403", "404", "500" --> "Internal server error please try again later";
                    */

                /* errorDescription is optional. Returns BlueSnap's standard error description */

                // onType: function (tagId, cardType /*, cardData*/) {
                //     /* cardType will give card type, and only applies to ccn: AMEX, VISA, MASTERCARD, AMEX, DISCOVER, DINERS, JCB */
                //     if (null != cardData) {
                //         /* cardData is an optional parameter which will provide ccType, last4Digits, issuingCountry, isRegulatedCard, cardSubType, binCategory and ccBin details (only applies to ccn) in a JsonObject */
                //         console.log(cardData);
                //     }
                // },
                onValid : (tagId : cardFields, errorCode: string) => {
                    console.log('on valid', tagId)
                    updateCodeValids(tagId, true)
                },
            },
            style: {
                "::placeholder": {
                    color : 'lightgrey'
                },
                input: {
                    "font-family": "PT Sans,Helvetica Neue,Helvetica,sans-serif",
                    "font-size": "14.1429px",
                    "line-height": "1.21428571",
                    "height": "14px",
                },
            },
            ccnPlaceHolder: "1234 5678 9012 3456", //for example
            cvvPlaceHolder: "123", //for example
            expPlaceHolder: "MM/YY", //for example
        };

        //Run the following command after Document Object Model (DOM) is fully loaded
        /*global bluesnap*/
        console.log("Creating payment fields")
        window.bluesnap.hostedPaymentFieldsCreate(bsObj);
    }

    const handleSubmit = (ev : SyntheticEvent) => {
        ev.preventDefault();
        setLoading(true)

        /*global bluesnap*/
        window.bluesnap.hostedPaymentFieldsSubmitData( async (callback : any) => {
            if (null != callback.cardData) {
                console.log('card type: ' + callback.cardData.ccType +
                    ', last 4 digits: ' + callback.cardData.last4Digits +
                    ', exp: ' + callback.cardData.exp +
                    ', issuing Country: ' + callback.cardData.issuingCountry +
                    ', isRegulatedCard: ' + callback.cardData.isRegulatedCard +
                    ', cardSubType: ' + callback.cardData.cardSubType +
                    ', binCategory: ' + callback.cardData.binCategory +
                    ' and ccBin: ' + callback.cardData.ccBin +
                    ', after that I can call final submit');

                let success = await subsContext.attachPaymentCard(pfToken!, firstNameOnCard, lastNameOnCard)

                    subsContext.refresh();
                    if (success)
                        onCardAdded()
                // submit the form
            } else {
                let errorArray = callback.error;
                for (let i in errorArray) {
                    console.log("Received error: tagId= " +
                        errorArray[i].tagId + ", errorCode= " +
                        errorArray[i].errorCode + ", errorDescription= " +
                        errorArray[i].errorDescription);
                }
            }
            setLoading(false)
        });
    }

    //handleChange = (e, {name, value}) => this.setState({[name] : value});
    
        return (
            <Modal open={visible} size='tiny'
                   trigger={
                       <Button content={textResources.Strings.addPaymentCard} size='large' color='green' fluid
                               onClick={open} /> } >
                <Modal.Header>
                    <Grid>
                        <Grid.Column width={12}>
                            <Header content={textResources.Strings.addPaymentCard}/>
                        </Grid.Column>
                        <Grid.Column textAlign='right' width={4}>
                            <Label icon='lock' color='green' content={textResources.Strings.secure} size='large'/>
                        </Grid.Column>
                    </Grid>
                </Modal.Header>
                <Modal.Content>
                    <Header size='small' color='blue' content={textResources.Strings.firstName}/>
                    <Segment size='mini' >
                        <Input placeholder={textResources.Strings.firstName} fluid transparent size='big'
                               name='firstNameOnCard' onChange={(e, data) => setFirstNameOnCard(data.value)} />
                    </Segment>

                    <Header size='small' color='blue' content={textResources.Strings.lastName}/>
                    <Segment size='mini' >
                        <Input placeholder={textResources.Strings.lastName} fluid transparent size='big'
                               name='lastNameOnCard' onChange={(e, data) => setLastNameOnCard(data.value)} />
                    </Segment>

                    <Header size='small' color='blue' content={textResources.Strings.cardNumber}/>
                    <Segment size='mini' color={!ccvValid ? 'red' : undefined}>
                        <div data-bluesnap="ccn" />
                    </Segment>

                    { ccnValid ? null :
                        <Message error size='tiny' content={textResources.Strings.enterValidCardNumber}/>
                    }

                    <Header size='small' color='blue' content={textResources.Strings.expirationDate}/>
                    <Segment size='mini' color={!expValid ? 'red' : undefined}>
                        <div data-bluesnap="exp" />
                    </Segment>

                    { expValid ? null :
                        <Message error size='tiny' content={textResources.Strings.enterValidExpirationDate}/>
                    }

                    <Header size='small' color='blue' content='CVC'/>
                    <Segment size='mini' color={!ccvValid ? 'red' : undefined}>
                        <div data-bluesnap="cvv" />
                    </Segment>

                    { ccvValid ? null :
                        <Message error size='tiny' content={textResources.Strings.enterValidVerificationCode}/>
                    }
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={close}
                            disabled={loading} content={textResources.Strings.cancel}/>
                    <Button content={textResources.Strings.addPaymentCard} color='blue'
                            loading={loading} onClick={handleSubmit}
                            disabled={!firstNameOnCard || !lastNameOnCard ||
                            !ccnValid || !expValid ||
                            !ccvValid || !tokenLoaded || loading  } />
                </Modal.Actions>
            </Modal>
        )
}
