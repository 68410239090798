import {Button, Grid, Header, Input, Message, Modal} from "semantic-ui-react";
import {textResources} from "../language/TextResources";
import {useContext, useEffect, useState} from "react";
import {LanguageContext} from "../contexts/LanguageContext";
import {ManagementConsoleAPIContext} from "@ametektci/ametek.stcappscommon";

interface ChangeEmailModalProps {
    currentEmail: string,
    propertyLabel: string
}

export function EmailChangeModal(props: ChangeEmailModalProps) {
    const translate = useContext(LanguageContext)
    const mgmtApi = useContext(ManagementConsoleAPIContext)
    const [open, setOpen] = useState(false)
    const [newEmail, setNewEmail] = useState(props.currentEmail)
    const [codeSent, setCodeSent] = useState(false)
    useEffect(() => {
        setNewEmail(props.currentEmail)
    },[props.currentEmail])
    const cancel = () => {
        setNewEmail(props.currentEmail)
        setCodeSent(false)
        setOpen(false)
    }
    const sendCodes = () => {
        setCodeSent(true)
        mgmtApi.startChangeEmail(newEmail)
    }
    return (
        <Modal
            trigger={<ChangeEmailModalTrigger
                currentEmail={props.currentEmail}
                propertyLabel={props.propertyLabel}
                modalOpen={() => setOpen(true)}
            />}
            open={open}
            onClose={() => setOpen(false)}
        >
            <Modal.Header>
                <Header>{translate.getString("updateEmail")}</Header>
            </Modal.Header>
            <Modal.Content>

                <Input
                    fluid
                    label={props.propertyLabel}
                    value={newEmail}
                    disabled={codeSent}
                    onChange={(e, {value}) => setNewEmail(value)}
                />
                <Message hidden={!codeSent} success content={translate.getString("confirmationSent")} />

            </Modal.Content>
            <Modal.Actions>
                {codeSent?
                    <Button 
                        content={translate.getString("close")}
                        onClick={cancel}
                        color={"blue"}
                        icon={"close"}
                    />
                :
                    <>
                <Button
                    content={translate.getString("sendLink")}
                    onClick={sendCodes}
                    color={"blue"}
                    icon={"mail"}
                    disabled={codeSent}
                />
                <Button 
                    onClick={cancel} 
                    color={"red"} 
                    icon="cancel" 
                    content={translate.getString("cancel")}
                />
                    </>
            }
            </Modal.Actions>
        </Modal>
    )
}

function ChangeEmailModalTrigger(props: { currentEmail: string, propertyLabel: string, modalOpen: () => void }) {
    return (
        <>
            <Header dividing content={props.propertyLabel} color='blue' size='small'/>
            <Grid columns={2}>
                <Grid.Row>
                    <Grid.Column width={10}>
                        <strong>{props.currentEmail}</strong>
                    </Grid.Column>
                    <Grid.Column width={6}>
                        <Button content={textResources.Strings.edit} onClick={props.modalOpen}
                                fluid icon={"edit"}/>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>
    )
}