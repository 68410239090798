import {useEffect} from "react";
import CookieConsent from "../utils/CookieConsent";
import {MainLandingBox} from "./MainLandingBox";
import {FeaturesBox} from "./FeaturesBox";
import {LinkBox} from "./LinkBox";
import {LandingHeader} from "./LandingHeader";

export function Landing ()
{
    useEffect(() => {
        CookieConsent()
    })
    return <div style={{display: "flex", flexDirection:"column" , minHeight:"100vh"}}>
        <LandingHeader />
        <MainLandingBox />
        <FeaturesBox />
        <LinkBox />
    </div>
}