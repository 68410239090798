import {Button} from "semantic-ui-react";
import {useContext, useState} from "react";
import {LanguageContext} from "../contexts/LanguageContext";
import {OrganizationContext} from "../contexts/OrganizationContext";
import {User} from "@ametektci/ametek.stcappscommon";

export function ReSendInvite({user}:{user: User})
{
    enum sentStatus {
        notSent,
        sending,
        sent,
        failed,
    }
    const translate = useContext(LanguageContext)
    const orgContext = useContext(OrganizationContext)
    const [sent, setSent] = useState(sentStatus.notSent)
    const send = () => {
        setSent(sentStatus.sending)
        orgContext.reSendInvite(user.userId).catch(() => {
            setSent(sentStatus.failed)
        }).then(() => {
            setSent(sentStatus.sent)
        })
        
    }
    
    if (user.username)
        return null //this user has already accepted the invite.
    return (
        <Button 
            size={"tiny"} 
            disabled={sent != sentStatus.notSent}
            loading = {sent == sentStatus.sending}
            color={"orange"}
            content={translate.getString("sendInviteAgain")}
            onClick={sent == sentStatus.notSent ? send : undefined} />
            
    )
    
}