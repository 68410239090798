import {Button, Container, Divider, Grid, Segment, Transition, Image} from "semantic-ui-react";
import {AuthContext} from "@ametektci/ametek.stcappscommon"
import stcBlackLogo from "../images/AmetekSTCBlack.svg"
import {useContext, useEffect, useState} from "react";
import {textResources} from "../language/TextResources";

export function MainLandingBox()
{
    const auth = useContext(AuthContext)
    const [getStartedBounceTrigger, setGetStartedBounceTrigger] = useState(true)
    const signUp = async () => {
        let addr = await auth.GetSignUpLink()
        if (addr)
            window.location.href = addr
    }
    useEffect(() => {
        setInterval(() => setGetStartedBounceTrigger(!getStartedBounceTrigger), 5000)
    })
    return (
        <Segment style={{
            backgroundSize: 'cover',
            backgroundColor:'darkgray',
            margin:0,
            flexGrow: 1,
        }} attached >
            <Grid>
                <Grid.Column largeScreen={4} computer={2} tablet={1} mobile={1}  />
                <Grid.Column largeScreen={8} computer={12} tablet={14} mobile={14}  >
                    <Segment size='huge' style={{marginTop : '75px'}}>
                        <Image size='huge' src={stcBlackLogo} />

                        <Divider hidden/>

                        <Container>
                            {textResources.Strings.applicationDescription}
                        </Container>
                    </Segment>
                </Grid.Column>
                <Grid.Column largeScreen={4} computer={2} tablet={1} mobile={1} />
            </Grid>
            <Transition animation='pulse' duration={1000} visible={getStartedBounceTrigger}>
                <Container textAlign='center' style={{
                    marginTop: '50px',
                    marginBottom: '50px',
                }}>
                    <Button content={textResources.getTextDynamic("getStarted")} size='massive' color='green' onClick={signUp} />
                </Container>
            </Transition>
        </Segment>
    )
}