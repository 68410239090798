import {textResources} from "../language/TextResources";
import {Button, Grid, Header, Input} from "semantic-ui-react";
import {useEffect, useState} from "react";

interface SometimesEditableTextProps {
    propertyLabel: string
    onSubmit: (value: string) => Promise<void>
    value: string
}

export function IndividuallyEditableText(props: SometimesEditableTextProps) {
    const [loading, setLoading] = useState(false)
    const [currentValue, setCurrentValue] = useState(props.value)
    const [edit, setEdit] = useState(false)
    useEffect(() => {
        setCurrentValue(props.value)
        setEdit(false)
    }, [props.value])
    const onChange = () => {
        setLoading(true)
        props.onSubmit(currentValue).then(() => {
            setLoading(false)
            setEdit(false)
        })
    }
    const cancel = () => {
        setEdit(false)
        setCurrentValue(props.value)
    }
    if (edit)
        return (
            <>
                <Header dividing content={props.propertyLabel} color='blue' size='small'/>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={9}>
                            <Input value={currentValue} onChange={(e, {value}) => setCurrentValue(value)}
                                   fluid disabled={loading}/>
                        </Grid.Column>
                        <Grid.Column width={7}>
                            <span style={{width: '50%'}} >
                            <Button
                                content={textResources.Strings.cancel}
                                onClick={cancel} disabled={loading}
                                icon={"cancel"}
                            />
                                </span>
                            <span style={{width: '50%'}} >
                            <Button
                                content={textResources.Strings.save}
                                onClick={onChange}
                                disabled={loading} loading={loading}
                                color="blue"
                                icon={"save"}
                            />
                            </span>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </>
        )
    return (
        <>
            <Header dividing content={props.propertyLabel} color='blue' size='small'/>
            <Grid columns={2}>
                <Grid.Row>
                    <Grid.Column width={10}>
                        <strong>{currentValue}</strong>
                    </Grid.Column>
                    <Grid.Column width={6}>
                        <Button content={textResources.Strings.edit} onClick={() => setEdit(true)}
                                disabled={loading} fluid icon={"edit"} />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>
    )
}