import {Container, Grid, Header, List, Segment, Image} from "semantic-ui-react";
import {Images} from "@ametektci/ametek.stcappscommon";
import {textResources} from "../language/TextResources";
import {GetEnvironmentSetting} from "../utils/EnvironmentSettings";

export function LinkBox() {
    return (<Segment inverted style={{padding: '4em 0em', flexGrow:0}} attached={true}  >
        <Container>
            <Grid divided inverted stackable>
                <Grid.Row>
                    <Grid.Column width={6}>
                        <Image src={Images.stcLogo} size='medium'/>
                    </Grid.Column>
                    <Grid.Column width={5}>
                        <Header inverted as='h4' content={textResources.Strings.applicationName} />
                        <List link inverted>
                            <List.Item as='a' content={textResources.Strings.crystalControlWeb} href={GetEnvironmentSetting('ccwUrl')} target={"_blank"} disabled={!GetEnvironmentSetting('ccwUrl')}/>
                            {/*Offline client is not ready.*/}
                            {/*<List.Item as='a' content={localeStore.Strings.offlineAppName} onClick={() => updatesStore.downloadUpdateFile(Products.OfflineClient)} />*/}
                            <List.Item as='a' content={textResources.Strings.documentation} href={"https://help.crystalcontrolweb.com/"}/>
                        </List>
                    </Grid.Column>
                    <Grid.Column width={5}>
                        <Header inverted as='h4' content='AMETEK' />
                        <List link inverted>
                            <List.Item as='a' href='https://www.ametekcalibration.com/' content={textResources.Strings.ametekCalibration}/>
                        </List>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    </Segment>)
}