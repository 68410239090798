import {Divider, Grid, Header, Segment,} from 'semantic-ui-react'
import {textResources} from "../language/TextResources";
//import ResetPassword from "../users/ResetPassword";
import {ProfileSection} from "./ProfileSection";
import {OrganizationSection} from "./OrganizationSection";
import {ApplicationLaunchSection} from "./ApplicationLaunchSection";

export default function ProfilePanel() {

    return (
        <Segment>
            <Header content={textResources.Strings.userProfile} size='huge'/>
            <Divider/>
            <Grid stackable>
                <Grid.Column width={8}>
                    <ProfileSection/>
                </Grid.Column>
                <Grid.Column width={8}>
                    <OrganizationSection/>
                    <ApplicationLaunchSection />
                </Grid.Column>
            </Grid>
        </Segment>
    )
}
