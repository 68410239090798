import {Button, Container, Image, Menu} from "semantic-ui-react";
import {AuthContext, Images} from "@ametektci/ametek.stcappscommon";
import {textResources} from "../language/TextResources";
import {useContext} from "react";

export function LandingHeader() {
    const auth = useContext(AuthContext)
    const signIn = async () => {
        let addr = await auth.authorize()
        if (addr)
            window.location.href = addr
    }
    const signUp = async () => {
        let addr = await auth.GetSignUpLink()
        if (addr)
            window.location.href = addr
    }
    return (
        <Menu attached={"top"} inverted borderless size='huge' style={{flexGrow:0}} >
            <Container>
                <Menu.Item>
                    <Image src={Images.stcLogo} size='medium' />
                </Menu.Item>

                <Menu.Menu position='right'>
                    <Menu.Item>
                        <Button content={textResources.getTextDynamic("signIn")} inverted onClick={signIn} disabled={!auth.ready} />
                    </Menu.Item>
                    <Menu.Item>
                        <Button content={textResources.getTextDynamic("signUp")} inverted color={"green"} onClick={signUp} disabled={!auth.ready} />
                    </Menu.Item>
                </Menu.Menu>
            </Container>
        </Menu>
    )
}