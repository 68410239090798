import {Header, Segment} from "semantic-ui-react";
import {textResources} from "../language/TextResources";
import {LeaveOrg} from "../users/LeaveOrg";
import {useContext} from "react";
import {IndividuallyEditableText} from "./IndividuallyEditableText";
import {ManagementConsoleAPIContext, messageQueue} from "@ametektci/ametek.stcappscommon";

export function OrganizationSection() {
    const mgmtContext = useContext(ManagementConsoleAPIContext)
    const users = mgmtContext.org?.users ?? []
    const otherAdmins = users.filter(u => u.isAdmin && u.username != mgmtContext.user!.username)
    const renameOrg = async (newName: string) => {
        await mgmtContext.updateOrgName(newName)
            .catch(() => {
                messageQueue.sendError(textResources.Strings.errorHasOccurred, textResources.Strings.error);
            })
    }
    return (
        <Segment>
            <Header content={textResources.Strings.organization} size='large'/>
            {mgmtContext.user?.isAdmin ?
                <IndividuallyEditableText
                    propertyLabel={textResources.Strings.organizationName}
                    onSubmit={renameOrg}
                    value={mgmtContext.org?.name ?? ""}
                /> :
                <>
                    <Header dividing content={textResources.Strings.organizationName} color='blue' size='small'/>
                    <Header content={mgmtContext.org?.name ?? ""} size='small'/>
                </>
            }
            <Header dividing content={textResources.Strings.leaveOrg} color={"red"} size={"small"}/>
            {users.length > 1 && otherAdmins.length == 0 ? textResources.Strings.assignAnotherAdminFirst : <LeaveOrg/>}
        </Segment>
    )
}