import {Image, Menu, Container, Dropdown, Icon} from 'semantic-ui-react';
import ametekLogo from '../images/AmetekSTC.svg';
import {ManagementConsoleAPIContext} from '@ametektci/ametek.stcappscommon';
import {textResources} from "../language/TextResources";
import LanguageSelection from "../language/LanguageSelection";
import {useContext} from "react";
import {OrganizationContext} from "../contexts/OrganizationContext";
import {ConnectionInfo} from "./ConnectionInfo";
import {AuthContext} from "@ametektci/ametek.stcappscommon/src/Authentication/AmetekOAuth";

export default function AppHeader() {
    const auth = useContext(AuthContext)
    const mgmtApi =useContext(ManagementConsoleAPIContext)
    const orgContext = useContext(OrganizationContext)
        let name = mgmtApi.user?.name ?? "";
        if (orgContext.user) {
            name = orgContext.user.name;
        }

        return (
            <Menu attached={true} inverted size='large'>
                <Container>
                    <Menu.Item href='/'>
                        <Image src={ametekLogo} size='small' className='headerImage'/>
                        {textResources.Strings.stcManagement}
                    </Menu.Item>

                    <Menu.Menu position='right'>

                        <Dropdown item text={name as string}>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={async () => {
                                    let signOut = await auth.signOut()
                                    if (signOut)
                                        window.location.href = signOut
                                }}>
                                    <Icon name='sign-out' color='red'/>
                                    {textResources.Strings.signOut}
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>

                        <LanguageSelection />
                        <ConnectionInfo />
                    </Menu.Menu>
                    
                </Container>
            </Menu>
        )
}
