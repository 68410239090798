const _localSettings = {
    'documentationUrl' : 'https://help.crystalcontrolweb.com',
    'managementConsoleApi' : 'https://localhost:5001',
    'CcwApiUrl' : 'https://dev-api.crystalcontrolweb.com',
    'ccwUrl' : 'https://localhost:3006',
}

type envVar = keyof typeof _localSettings
export const GetEnvironmentSetting = function(key : envVar) {
    let env = ExportEnvironmentSettings()
    let value = env[key];
    if ( value == null ) {
        console.error('unknown environment variable', key);
    }
    return value;
};

export const ExportEnvironmentSettings = function() {
    if (window.location.hostname.includes('localhost'))
        return _localSettings
    else
        return window.ENV
};
