import {useContext} from 'react'
import {Menu} from 'semantic-ui-react'
import {textResources} from "../language/TextResources";
import {OrganizationContext} from "../contexts/OrganizationContext";
import {Outlet, useLocation, useNavigate} from "react-router-dom"

export default function FullAccess() {
    const orgContext = useContext(OrganizationContext)
    const navigate = useNavigate()
    const location = useLocation()
    const url = location.pathname.split("/").filter(piece => piece != "")
    return (
        <div style={{marginTop:"1em"}}>
            <Menu pointing secondary>
                <Menu.Item
                    content={textResources.Strings.profile}
                    name='profile'
                    active={url[0] === 'Profile'}
                    onClick={() => navigate('/Profile')}
                />
                {orgContext.user?.isAdmin? 
                <>
                    <Menu.Item
                        content={textResources.Strings.users}
                        name='users'
                        active={url[0] === 'Users'}
                        onClick={() => navigate('/Users')}
                    />
                    <Menu.Item
                        content={textResources.Strings.billing}
                        name='billing'
                        active={url[0] === 'Billing'}
                        onClick={() => navigate('/Billing')}
                    />
                </>: null
                }
            </Menu>
            <Outlet />
        </div>
    )
}