import {useContext} from 'react'
import {Header, List, Segment} from 'semantic-ui-react'
import InviteUser from "./InviteUser";
import {textResources} from "../language/TextResources";
import {OrganizationContext} from "../contexts/OrganizationContext";
import {UserListing} from "./UserListing";

export default function UsersPanel() {
    const orgContext = useContext(OrganizationContext)
    const users = orgContext.getOrganizationUsers()

        return (
            <Segment>
                <Header content={textResources.Strings.users} size='huge'/>

                <Header size='small' content={textResources.Strings.emailInviteLink} dividing/>
                <InviteUser/>

                <Header size='small' content={textResources.Strings.currentUsers} dividing/>
                <List>
                    {users.map(user => (
                        <UserListing user={user} />
                    ))}
                </List>
            </Segment>
        )

}