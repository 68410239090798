import {textResources} from "../language/TextResources";
import {Card, Container, Header, Image} from "semantic-ui-react";
import BillingPreivew from "../images/Preview/BillingPreview.jpg"
import UsersPreview from "../images/Preview/UsersPreview.jpg"
import AccountManagementPreview from "../images/Preview/AccountInfoPreview.jpg"

export function FeaturesBox() {
    return (
        <div style={{
            backgroundColor: '#2e2f2f',
            //minHeight: '700px',
            padding: '70px 0',
            flexGrow: 1
        }}>
            <Container>
                <Header inverted content={textResources.getTextDynamic('features')} size='huge'/>
                <Card.Group itemsPerRow={3} stackable>
                    <Card>
                        <Image src={AccountManagementPreview} wrapped ui={false}/>
                        <Card.Content
                            header={textResources.getTextDynamic('featureManageAccount')}
                            description={textResources.getTextDynamic('featureManageAccountDescription')}
                        />
                    </Card>
                    <Card>
                        <Image src={UsersPreview} wrapped ui={false}/>
                        <Card.Content
                            header={textResources.getTextDynamic('featureManageUsers')}
                            description={textResources.getTextDynamic('featureManageUsersDescription')}
                        />
                    </Card>
                    <Card>
                        <Image src={BillingPreivew} wrapped ui={false}/>
                        <Card.Content
                            header={textResources.getTextDynamic('featureManagePayments')}
                            description={textResources.getTextDynamic('featureManagePaymentsDescription')}
                        />
                    </Card>
                </Card.Group>
            </Container>
        </div>
    )
}