import {createContext, PropsWithChildren, useContext, useEffect, useState} from "react";
import {GaugesContext} from "./GaugesContext";
import {CreditCardInfo, messageQueue, SubscriptionSeat} from "@ametektci/ametek.stcappscommon";
import axios, {AxiosResponse, InternalAxiosRequestConfig} from "axios";
import {GetEnvironmentSetting} from "../utils/EnvironmentSettings";
import {GetSubsRequest, GetSubsResponse} from "../Models/API/GetSubsRequest";
import {textResources} from "../language/TextResources";
import {OrganizationContext} from "./OrganizationContext";
import {BlueSnapShopper, DefaultShopper, ShippingContactInfo} from "../Models/Types/BlueSnapShopper";
import {BlueSnapHistory} from "../Models/Types/BlueSnapHistory";
import {Country, State} from "../Models/Types/Country";
import {AuthContext} from "@ametektci/ametek.stcappscommon/src/Authentication/AmetekOAuth";

export const SubscriptionsContext = createContext({
    Subs: [] as Array<SubscriptionSeat>,
    refresh: () => {},
    shopper: DefaultShopper,
    cancelSubscription: (subscriptionId: number) => Promise.resolve(),
    updateShopper: (newShopper: {
        zip: string;
        lastName: string;
        country: string;
        shopperCurrency: string;
        address: string;
        city: string;
        address2: string;
        companyName: string;
        firstName: string;
        paymentSources: { creditCardInfo: CreditCardInfo[] };
        vaultedShopperId?: number;
        phone: string;
        state: string | undefined;
        shippingContactInfo: ShippingContactInfo;
        email: string
    }) => Promise.resolve(),
    orderHistory: [] as Array<BlueSnapHistory>,
    loadMoreHistory: () => {},
    loadingHistory: false,
    allHistoryLoaded: true,
    attachPaymentCard: (pfToken: string, firstName: string, lastName: string) => Promise.resolve(false),
    removePaymentCard: (cardLastFour: string, cardType: string) => Promise.resolve(false),
    setCardAsDefault: (cardLastFour: string, cardType: string) => Promise.resolve(),
    countries: [] as Array<Country>,
    states: [] as Array<State>,
})

export function SubscriptionsContextWrapper(props: PropsWithChildren) {
    const auth = useContext(AuthContext)
    const orgContext = useContext(OrganizationContext)
    const [SubsOwnedByOrg, setSubsOwnedByOrg] = useState<SubscriptionSeat[]>([])
    const [SubsNotOwnedByOrg, setSubsNotOwnedByOrg] = useState<SubscriptionSeat[]>([])
    const [shopper, setShopper] = useState<BlueSnapShopper>(DefaultShopper)
    const [orderHistory, setOrderHistory] = useState<Array<BlueSnapHistory>>([]) 
    const [loadingHistory, setLoadingHistory] = useState(false)
    const [allHistoryLoaded, setAllHistoryLoaded] = useState(false)
    const [countries, setCountries] = useState<Country[]>([])
    const [states, setStates] = useState<State[]>([])
    const allSubs = SubsOwnedByOrg.concat(SubsNotOwnedByOrg)
    const gauges = useContext(GaugesContext).gauges
    const managementAxios = axios.create({
        baseURL: GetEnvironmentSetting("managementConsoleApi") as string,
    })
    const generateAuthHeader = async (request: InternalAxiosRequestConfig) => {
        request.headers.Authorization = "bearer " + await auth.getAccessToken()
        return request
    }
    managementAxios.interceptors.request.use(generateAuthHeader)
    useEffect(() => {
        getSubs()
    }, [gauges])
    useEffect(() => {
        getShopper()
        loadMoreHistory()
        loadCountries()
    },[])
    const getData = () => {
        getShopper()
        getSubs()
    }
    const orderBatchSize = 50;
    const lastOrder = () => {
        if (orderHistory.length == 0)
            return 0
        return orderHistory[orderHistory.length -1].id
    }
    const loadMoreHistory = () => {
        if (loadingHistory || allHistoryLoaded)
            return
        setLoadingHistory(true)
        managementAxios.get(`/OrderHistory?previousMax=${lastOrder()}&count=${orderBatchSize}`)
            .then((response) => {
                setOrderHistory(response.data.history)
                if (response.data.history.length < orderBatchSize)
                    setAllHistoryLoaded(true)
        })
            .finally(() => setLoadingHistory(false))
    }
    const loadCountries = async () => {
        managementAxios.get("/Countries").then((response) => setCountries(response.data))
        managementAxios.get("/States").then((response) => setStates(response.data))
    }
    const getShopper = async () => {
        managementAxios.get("/Shopper")
            .then((shopResponse) => setShopper(shopResponse.data.shopper))
            .catch(() => messageQueue.sendError(textResources.Strings.unableToUpdateShopper))
    }
    
    const getSubs = async () => {
        setSubsNotOwnedByOrg([])
        setSubsOwnedByOrg([])
        let ownedResponse = await managementAxios.post<GetSubsResponse, AxiosResponse<GetSubsResponse>, GetSubsRequest>("/Billing/Subscriptions")
        setSubsOwnedByOrg(ownedResponse.data.seats)
        let noSubs = gauges.filter(g => !ownedResponse.data.seats.some(sub => sub.serialNumber == g.serialNumber))
        if (noSubs.length > 0) {
            let response = await managementAxios.post<GetSubsResponse, AxiosResponse<GetSubsResponse>, GetSubsRequest>("/Billing/Subscriptions",{
                SerialNumbers: noSubs.map(g => g.serialNumber)
            })
            setSubsNotOwnedByOrg(response.data.seats)
        }
    }
    const cancelSubscription = async (subscriptionId: number) => {
        await axios.post(GetEnvironmentSetting('managementConsoleApi') + "/Billing/CancelSubscription", {
            SubscriptionId: subscriptionId
        },{headers:{"Authorization":"bearer " + await auth.getAccessToken()}}).then((response) => {
            if (response.data.success) {
                orgContext.billingItems = orgContext.billingItems!.filter(s => s.subscriptionId !== subscriptionId);
                orgContext.organization!.seats = orgContext.organization!.seats.filter(s => s.blueSnapSubscriptionId !== subscriptionId);
            } else {
                messageQueue.sendError(response.data.errorMessage, textResources.Strings.subscriptionCancellationFailure);
            }
        }).catch((error) => {
            messageQueue.sendError(error.message, textResources.Strings.subscriptionCancellationFailure);
        })
        getSubs()
        return
    }
    const updateShopper = async (newShopper: BlueSnapShopper) => {
        await managementAxios.put("/Shopper", {shopper: newShopper})
            .then(response => setShopper(response.data.shopper))
    }
    const attachPaymentCard = async (pfToken: string, firstName: string, lastName: string) => {
        let response = await managementAxios.post("/Cards/Attach", {
            PfToken: pfToken,
            firstName: firstName,
            lastName: lastName,
        }, { validateStatus: status => true })
        if (response.status != axios.HttpStatusCode.Ok) {
            messageQueue.sendError(response.statusText, textResources.Strings.cardCreateFailure)
            return false;
        }
        await getShopper()
        console.log("after response", response)
        if (response.data.success) {
            return true
        } else {
            messageQueue.sendError(response.data.error, textResources.Strings.cardCreateFailure);
            return false
        }
    }
    const removePaymentCard = async (cardLastFourDigits: string, cardType: string) => {
        let response = await managementAxios.post("/Cards/Remove", {
            CardLastFourDigits: cardLastFourDigits,
            CardType: cardType
        })
        if (response.status != axios.HttpStatusCode.Ok) {
            messageQueue.sendError(response.statusText, textResources.Strings.cardRemoveFailure);
            return false;
        }
        await getShopper()
        if (response.data.success) {
            return true;
        } else {
            messageQueue.sendError(response.data.errorMessage, textResources.Strings.cardRemoveFailure);
            return false;
        }
    }
    const setCardAsDefault = async (cardLastFourDigits: string, cardType: string) => {
        let response = await managementAxios.post( "/Cards/SetDefault", {
            CardLastFourDigits: cardLastFourDigits,
            CardType: cardType
        })
        if (response.status != axios.HttpStatusCode.Ok) {
            messageQueue.sendError(response.statusText, textResources.Strings.cardSetToDefaultFailure);
            return
        }
        await getShopper()
        if (response.data.success) {

            console.log(textResources.Strings.cardSetToDefaultSuccess)
        } else {
            messageQueue.sendError(response.data.errorMessage, textResources.Strings.cardSetToDefaultFailure);
        }
    }
    return (
        <SubscriptionsContext.Provider value={{
            Subs: allSubs,
            shopper: shopper,
            refresh: getData,
            cancelSubscription,
            updateShopper,
            orderHistory,
            loadMoreHistory,
            allHistoryLoaded,
            loadingHistory,
            attachPaymentCard,
            removePaymentCard,
            setCardAsDefault,
            countries,
            states,
        }}>
            {props.children}
        </SubscriptionsContext.Provider>
    )
}