import {Button, Grid, Header, Image, Segment} from "semantic-ui-react";
import {textResources} from "../language/TextResources";
import ccwLogo from "../images/CCWeb_Logo_Black.svg";
import {GetEnvironmentSetting} from "../utils/EnvironmentSettings";

export function ApplicationLaunchSection() {
    return (
        <Segment>
            <Header content={textResources.Strings.applications} size='large' />

            <Segment >
                <Grid verticalAlign='middle'>
                    <Grid.Column width={8} >
                        <Image src={ccwLogo} size='medium' />
                    </Grid.Column>
                    <Grid.Column width={8} textAlign='right'>
                        <Button content={textResources.Strings.launch} icon='external' as='a' href={GetEnvironmentSetting('ccwUrl')+"/signIn"} target={"_blank"}/>
                    </Grid.Column>
                </Grid>
            </Segment>

        </Segment>
    )
}