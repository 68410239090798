import {useContext, useEffect} from 'react';
import FullAccess from "./navigation/FullAccess";
import ProfilePanel from "./profile/ProfilePanel";
import {AuthContext, LoadingPage, ManagementConsoleAPIContext, MessageFeed} from "@ametektci/ametek.stcappscommon"
import {Helmet} from "react-helmet-async";
import {APIContext} from "./contexts/APIContext";
import {
    createBrowserRouter,
    RouterProvider,
    Navigate,
    RouteObject,
} from "react-router-dom";
//import {CreateOrg} from "./JoinOrganization/CreateOrg";
import UsersPanel from "./users/UsersPanel";
import {GaugesContextWrapper} from "./contexts/GaugesContext";
import {SubscriptionsContextWrapper} from "./contexts/SubscriptionsContext";
import BillingPanel from "./billing/BillingPanel";
import SubscriptionBills from "./billing/SubscriptionBills";
import {PaymentHistory} from "./billing/PaymentHistory";
import PaymentMethods from "./billing/PaymentMethods";
import CookieConsent from "./utils/CookieConsent";
import {WithLayout} from "./WithLayout";
import {RememberedPage, OAuthLanding, NewUser, JoinOrg,VerifySwitch, EnvironmentConfigurationContext, SignInPage } from "@ametektci/ametek.stcappscommon";
import {Landing} from "./Landing/Landing";
import {ChangeEmail} from "./profile/ChangeEmail";
import {DeleteAccount} from "./users/DeleteAccount";
import {LanguageContext} from "./contexts/LanguageContext";
import Header from "./global/Header";
import {Notice} from "./global/Notice";
import {Container, Segment} from "semantic-ui-react";

export default function App() {
    const env = useContext(EnvironmentConfigurationContext)
    const translate = useContext(LanguageContext)
    const mgmtApi = useContext(ManagementConsoleAPIContext)
    const auth = useContext(AuthContext)
    const bluesnapLink = useContext(APIContext).blueSnapLink
    useEffect(() => {
        CookieConsent()
    }, [])
    useEffect(() => {
        console.log("Env from Management API have come in", mgmtApi.envData)
        if (mgmtApi.envData["documentationUrl"] != undefined)
            env.addValues({
                documentationUrl : mgmtApi.envData["documentationUrl"],
                CcwApiUrl : mgmtApi.envData["crystalControlWebApiUrl"],
                ccwUrl : mgmtApi.envData["crystalControlWebUrl"],
                cognito_region: mgmtApi.envData["cognitoRegion"],
                cognito_user_pool_id: mgmtApi.envData["cognitoUserPoolId"],
                cognito_client_id: mgmtApi.envData["cognitoClientId"],
                cognito_client_secret: mgmtApi.envData["cognitoClientSecret"],
                cognito_callback_url: mgmtApi.envData["cognitoCallbackUrl"],
                cognito_logOut_target: mgmtApi.envData["cognitoLogOutUrl"],
            })
    },[mgmtApi.envData])
    
    if (!auth.ready)
        return <LoadingPage/>

    let availablePages: RouteObject[]
    let loggedOutPages: RouteObject[] = [
        {
            path: "*",
            index: true,
            element: <RememberedPage><Landing/></RememberedPage>
        },
        {
            path: "signIn",
            element: <SignInPage/>
        },
        {
            path: "oauth",
            element: <>
                <Header/>
                <Notice />
                <Container>
                    <Segment>
                        <OAuthLanding/>
                    </Segment>
                </Container>
            </>
        },
        {
            path: "ChangeEmail",
            element: <RememberedPage><Landing/></RememberedPage>
        },
        {
            path: "join",
            element: <RememberedPage><Landing/></RememberedPage>
        }
        ]
    if (!mgmtApi.userLoaded)
        availablePages = [
            {
                path: "*",
                index: true,
                element: <LoadingPage />
            },
            {
                path: "",
                index: true,
                element: <LoadingPage />
            }
        ]
   else if (mgmtApi.org == undefined)
        availablePages = [
            {
                path: "*",
                index: true,
                element: <Navigate to={"/join"}/>
            },
            {
                path: "",
                element: <Navigate to={"/join"}/>
            },
        ]
    else {
        availablePages = [
            {
                path: "Profile",
                element: <ProfilePanel/>
            },
            {
                path: "signIn",
                element: <SignInPage/>
            },
            {
                path: "",
                element: <Navigate to={"/Profile"}/>
            },
            {
                path: "*",
                element: <Navigate to={"/Profile"}/>
            },
            {
                path:"ChangeEmail",
                element: <ChangeEmail/>
            },
            {
                path:"DeleteAccount",
                element: <DeleteAccount/>
            },
        ]
        if (mgmtApi.user?.isAdmin) {
            availablePages = availablePages.concat([
                {
                    path: "users",
                    element: <UsersPanel/>
                },
                {
                    path: "billing",
                    element: <GaugesContextWrapper><SubscriptionsContextWrapper>
                        <BillingPanel/></SubscriptionsContextWrapper></GaugesContextWrapper>,
                    children: [
                        {
                            path: "",
                            element: <Navigate to={"PaymentMethod"}/>
                        },
                        {
                            path: "*",
                            element: <Navigate to={"PaymentMethod"}/>
                        },
                        {
                            path: "ActiveSubscriptions",
                            element: <SubscriptionBills/>
                        },
                        {
                            path: "History",
                            element: <PaymentHistory/>
                        },
                        {
                            path: "PaymentMethod",
                            element: <PaymentMethods/>
                        }
                    ]
                },
            ])
        }
    }
    let router
    
    if (auth.signedIn)
        router = createBrowserRouter([
        {
            path: "/",
            element: <WithLayout><FullAccess/></WithLayout>,
            children: availablePages
        },
        {
            path: "/join",
            element: <WithLayout><VerifySwitch><NewUser/></VerifySwitch></WithLayout>
        },
        {
            path: "/join/:code",
            element: <WithLayout><VerifySwitch><JoinOrg returnToAppLabel={translate.getString("returnToConsole")}/></VerifySwitch></WithLayout>
        },
            {
                path: "oauth",
                index: true,
                element: <OAuthLanding />
            },
    ])
    else
        router = createBrowserRouter(loggedOutPages)
    return (
        <div className="App">
            <Helmet>
                {bluesnapLink && mgmtApi.user?.isAdmin ?
                    <script type="text/javascript" src={bluesnapLink + "/web-sdk/4/bluesnap.js"}></script>
                    : null}
            </Helmet>
            <RouterProvider router={router}/>
            <MessageFeed/>
        </div>
    );

}
