import {useContext, useEffect, useState} from "react";
import {ManagementConsoleAPIContext, AuthContext} from "@ametektci/ametek.stcappscommon";
import {LanguageContext} from "../contexts/LanguageContext";
import {Header, Message, Segment} from "semantic-ui-react";

export function ChangeEmail()
{
    const translate = useContext(LanguageContext)
    const authContext = useContext(AuthContext)
    const mgmtApi = useContext(ManagementConsoleAPIContext)
    const [changed, setChanged] = useState<boolean>()
    const ExtractCode = () => {
    let CurrentURL = window.location.href
    let url = new URL(CurrentURL)
    let code = url.searchParams.get("code")
    let email = url.searchParams.get("email")
    return [email, code]
}

    useEffect(() => {
        console.log("Extracting code")
        if (!authContext.ready)
            return;
        let [email,code] = ExtractCode()
        if (email && code)
            mgmtApi.finishChangeEmail(email, code)
                .then(([success,errorInfo]) =>{
                    setChanged(success)
                    console.error("Email change failure",errorInfo)
                } )
    }, [authContext.ready])
    switch (changed)
    {
        case undefined:
            return <Segment>
                <Header>{translate.getString("emailChangeHeader")}</Header>
                <Message>{translate.getString("shouldNotPersist")}</Message>
            </Segment>
        case true:
            return <Segment>
                <Header>{translate.getString("emailChangeHeader")}</Header>
                <Message success>{translate.getString("emailChangeSuccess")}</Message>
            </Segment>
        default:
            return <Segment> 
                <Header>{translate.getString("emailChangeHeader")}</Header>
                <Message error>{translate.getString("emailChangeFailure")}</Message>
            </Segment>
    }
        
}