import {Button, Modal} from "semantic-ui-react";
import {textResources} from "../language/TextResources";
import {useContext, useState} from "react";
import {ManagementConsoleAPIContext} from "@ametektci/ametek.stcappscommon";

export function DeleteAccount() {
    const mgmtApi = useContext(ManagementConsoleAPIContext)
    const [open, setOpen] = useState(false)
    const [emailSent, setEmailSent] = useState(false)
    const close = () => {
        setOpen(false)
    }
    const tryDelete = () => {
        mgmtApi.startDeleteUser()
        setEmailSent(true)
    }
    return (
        <Modal
             trigger={
                <Button content={textResources.Strings.deleteAccount} onClick={() => setOpen(true)} />
            }
               open={open}
               onClose={() => close()}
       >
            <Modal.Header>
                {textResources.Strings.deleteAccount}
            </Modal.Header>
            <Modal.Content>
                    {textResources.Strings.deleteAccountWarning}
            </Modal.Content>
            <Modal.Actions>
                <Button icon={emailSent? "check":"mail" } content={textResources.Strings.sendVerification} disabled={emailSent} onClick={() => tryDelete()} color={"red"}/>
                <Button content={textResources.Strings.cancel} onClick={() => close()} />
            </Modal.Actions>
        </Modal>
    )
}