import {AmetekOAuth,ManagementConsoleAPI} from '@ametektci/ametek.stcappscommon';
import App from "./App";
import {HelmetProvider} from 'react-helmet-async';
import {OrganizationContextWrapper} from "./contexts/OrganizationContext";
import {ApiContextWrapper} from "./contexts/APIContext";
import {LanguageContextWrapper} from "./contexts/LanguageContext";
import {EnvironmentConfig} from "@ametektci/ametek.stcappscommon/src/GeneralContexts/EnvironmentConfig";
import {ExportEnvironmentSettings} from "./utils/EnvironmentSettings";

export default function AppWithAuth() {

    return (
        <LanguageContextWrapper>
            <EnvironmentConfig env={ExportEnvironmentSettings()} >
                <AmetekOAuth>
                    <ManagementConsoleAPI>
                        <ApiContextWrapper>
                            <HelmetProvider>
                                <OrganizationContextWrapper>
                                    <App/>
                                </OrganizationContextWrapper>
                            </HelmetProvider>
                        </ApiContextWrapper>
                    </ManagementConsoleAPI>
                </AmetekOAuth>
            </EnvironmentConfig>
        </LanguageContextWrapper>
    )
}